import { Edit, useAutocomplete  } from "@refinedev/mui";
import { Box, TextField, Autocomplete, FormControl, FormLabel, ToggleButtonGroup, ToggleButton  } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";

export const ObraEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const obrasData = queryResult?.data?.data;

    const { autocompleteProps: grupoObrasAutocompleteProps } = useAutocomplete({
        resource: "gruposobras",
        defaultValue: obrasData?.idGrupoObra,
    });

    const { autocompleteProps: responsavelObraAdmAutocompleteProps } = useAutocomplete({
        resource: "usuarios",
        defaultValue: obrasData?.idUsuarioResponsavelAdm,
    });    

    const { autocompleteProps: responsavelObraGoAutocompleteProps } = useAutocomplete({
        resource: "usuarios",
        defaultValue: obrasData?.idUsuarioResponsavelGo,
    });   

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("id", {
                        required: translate("validations.requiredField"),
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("pages.obras.fields.id")}
                    name="id"
                    disabled
                />
                <TextField
                    {...register("nomeObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.nomeObra}
                    helperText={(errors as any)?.nomeObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.obras.fields.nomeObra")}
                    name="nomeObra"
                />
                <TextField
                    {...register("descObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.descObra}
                    helperText={(errors as any)?.descObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.obras.fields.descObra")}
                    name="descObra"
                />                
                <TextField
                    {...register("numeroInternoObra", {
                        required: translate("validations.requiredField"),
                        valueAsNumber: true,
                    })}
                    error={!!(errors as any)?.numeroInternoObra}
                    helperText={
                        (errors as any)?.numeroInternoObra?.message
                    }
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    label={translate("pages.obras.fields.numeroInternoObra")}
                    name="numeroInternoObra"
                />                            
                <TextField
                    {...register("codigoInternoObra", {
                        required: translate("validations.requiredField"),
                    })}
                    error={!!(errors as any)?.codigoInternoObra}
                    helperText={(errors as any)?.codigoInternoObra?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={translate("pages.obras.fields.codigoInternoObra")}
                    name="codigoInternoObra"
                />
            <Controller
                    control={control}
                    name="idUsuarioResponsavelAdm"
                    rules={{ required: translate("validations.requiredField") }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...responsavelObraAdmAutocompleteProps}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            getOptionLabel={(item) => {
                                if(typeof(item) === 'object'){
                                    return(
                                        item.id + ' - ' +
                                        responsavelObraAdmAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }else{
                                    return(
                                        item + ' - ' +
                                        responsavelObraAdmAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() === value?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("pages.obras.fields.nomeResponsavelObraAdm")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.idUsuarioResponsavelAdm}
                                    helperText={
                                        (errors as any)?.idUsuarioResponsavelAdm?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />  
            <Controller
                    control={control}
                    name="idUsuarioResponsavelGo"
                    rules={{ required: translate("validations.requiredField") }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...responsavelObraGoAutocompleteProps}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            getOptionLabel={(item) => {
                                if(typeof(item) === 'object'){
                                    return(
                                        item.id + ' - ' +
                                        responsavelObraGoAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }else{
                                    return(
                                        item + ' - ' +
                                        responsavelObraGoAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.toString(),
                                        )?.nomeUsuario ?? ""
                                    );
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() === value?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("pages.obras.fields.nomeResponsavelObraGo")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.idUsuarioResponsavelGo}
                                    helperText={
                                        (errors as any)?.idUsuarioResponsavelGo?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />  
                <Controller
                    control={control}
                    name="idGrupoObra"
                    rules={{ required: translate("validations.requiredField") }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            {...grupoObrasAutocompleteProps}
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            getOptionLabel={(item) => {
                                if(typeof(item) === 'object'){
                                    return(
                                        item.id + ' - ' +
                                        grupoObrasAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.id?.toString(),
                                        )?.nomeGrupoObra ?? ""
                                    );
                                }else{
                                    return(
                                        item + ' - ' +
                                        grupoObrasAutocompleteProps?.options?.find(
                                            (p) =>
                                                p?.id?.toString() ===
                                                item?.toString(),
                                        )?.nomeGrupoObra ?? ""
                                    );
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                value === undefined ||
                                option?.id?.toString() === value?.toString()
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate("pages.obras.fields.idGrupoObra")}
                                    margin="normal"
                                    variant="outlined"
                                    error={!!(errors as any)?.idGrupoObra}
                                    helperText={
                                        (errors as any)?.idGrupoObra?.message
                                    }
                                    required
                                />
                            )}
                        />
                    )}
                />         

                <FormControl>
                    <FormLabel>{translate("pages.obras.fields.flAtivo.label")}</FormLabel>
                        <Controller
                            control={control}
                            name="flAtivo"
                            rules={{
                            validate: (value) => {
                                if (value === undefined) {
                                return translate("validations.requiredField", {
                                    field: "flAtivo",
                                });
                                }
                                return true;
                            },
                            }}
                            defaultValue={false}
                            render={({ field }) => (
                            <ToggleButtonGroup            
                                id="flAtivo"                        
                                {...field}
                                exclusive
                                color="primary"
                                onChange={(_, newValue) => {
                                setValue("flAtivo", newValue, {
                                    shouldValidate: true,
                                });

                                return newValue;
                                }}
                            >
                                <ToggleButton value={true}>
                                {translate("pages.usuarios.fields.flAtivo.true")}
                                </ToggleButton>
                                <ToggleButton value={false}>
                                {translate("pages.usuarios.fields.flAtivo.false")}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}               
                    />    
                </FormControl>                        

            </Box>
        </Edit>
    );
};
