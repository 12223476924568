import { HttpError,useApiUrl,useCustom,usePermissions,useTranslate } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { UseModalFormReturnType } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import { IAppPermission, IItemPedido, Nullable } from "../../interfaces";
import { InputAdornment } from "@mui/material";
import { useEffect, useId, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";


export const EditItemPedidoDrawer: React.FC<
    UseModalFormReturnType<IItemPedido, HttpError, Nullable<IItemPedido>>
> = ({
    saveButtonProps:{onClick},
    refineCore: { queryResult },
    modal: { visible, close,title },
    register,
    control,
    formState: { errors },    
}) => {
    const translate = useTranslate();
    const apiUrl = useApiUrl();
    const { id } = useParams();
    let {permissionsData} : IAppPermission = {};
    permissionsData = usePermissions()?.data;
    

    const [itemqtd, setItemqtd] = useState<any>(0);
    const [itempreco, setItempreco] = useState<any>(0);
    const [itemprecototal, setItemprecototal] = useState<any>(0);

    useEffect(() => {
        if(queryResult?.isFetched){
            setItemqtd(queryResult?.data?.data?.valorItemQuantidade);
            setItempreco(queryResult?.data?.data?.valorPrecoItemUnidade);
            setItemprecototal(queryResult?.data?.data?.valorItemTotal);
        }
     }, [queryResult?.isFetched]); 

     useEffect(() => {
        setItemprecototal(itemqtd*itempreco);
     }, [itemqtd,itempreco]); 

     const handleChange = (event: { target: { name: any; value: string; }; }) => {
        switch (event.target.name) {
          case "valorItemQuantidade":
            setItemqtd(parseFloat(event.target.value));
            break;
          case "valorPrecoItemUnidade":
            setItempreco(parseFloat(event.target.value));
            break;
        }        
    }     

    const { data:dataPedido, isLoading: pedidoIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/${id}`,
        method: "get",
        config:{
            query:{
            },         
        },
        queryOptions: {
            enabled: true,
        },        
      });

    const canEdit = React.useMemo(():boolean=>{
        return (
            permissionsData?.status_profile?.some(
                (item: { idStatus: any; acessoEdicao: boolean }) =>
                    item.idStatus === dataPedido?.data?.idStatus &&
                    item.acessoEdicao
            ) &&
            dataPedido?.data?.obra?.flAtivo
        );          
    },[dataPedido]);        

    return (
        <Drawer
            open={visible}
            onClose={close}
            anchor="right"
            PaperProps={{ sx: { width: { sm: "100%", md: 500 } } }}
        >
            <Edit
                saveButtonProps={{disabled:!canEdit,
                                   onClick: onClick,
                                }}
                headerProps={{
                    action: (
                        <IconButton
                            onClick={() => close()}
                            sx={{ width: "30px", height: "30px" }}
                        >
                            <CloseOutlined />
                        </IconButton>
                    ),
                    avatar: null,
                    title:translate("pages.pedidos.itens.titles.edit")
                }}
                wrapperProps={{ sx: { overflowY: "scroll", height: "100%" } }}                             
            >
                <Box
                    component="form"
                    autoComplete="off"
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <TextField
                        {...register("id", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.id}
                        helperText={(errors as any)?.id?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label={translate("pages.pedidos.itens.fields.id")}
                        name="id"
                        disabled
                    />
                    {/* <TextField
                        {...register("codigoItemIncorporator", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoItemIncorporator}
                        helperText={(errors as any)?.codigoItemIncorporator?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.codigoItemIncorporator")}
                        name="codigoItemIncorporator"
                        //autoFocus
                    />                     */}
                    <TextField
                        {...register("codigoDiagramaOrdem", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoDiagramaOrdem}
                        helperText={(errors as any)?.codigoDiagramaOrdem?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.codigoDiagramaOrdem")}
                        name="codigoDiagramaOrdem"
                        disabled={!canEdit}
                    /> 
                    <TextField
                        {...register("descItemPedido", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.descItemPedido}
                        helperText={(errors as any)?.descItemPedido?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label={translate("pages.pedidos.itens.fields.descItemPedido")}
                        name="descItemPedido"
                        disabled={!canEdit}
                    />    
                    <TextField
                        {...register("codigoTributo", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.codigoTributo}
                        helperText={(errors as any)?.codigoTributo?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        style={{
                            width: "220px"
                        }}
                        label={translate("pages.pedidos.itens.fields.codigoTributo")}
                        name="codigoTributo"
                        disabled={!canEdit}
                    />                                         
                    <TextField
                        {...register("valorItemQuantidade", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemQuantidade}
                        helperText={(errors as any)?.valorItemQuantidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px"
                        }}     
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                                                           
                        label={translate("pages.pedidos.itens.fields.valorItemQuantidade")}
                        name="valorItemQuantidade"
                        value={itemqtd} 
                        onChange={handleChange}
                        disabled={!canEdit}
                    />
                    <TextField
                        {...register("descItemUnidade", {
                            required: "This field is required",
                        })}
                        error={!!(errors as any)?.descItemUnidade}
                        helperText={(errors as any)?.descItemUnidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        style={{
                            width: "100px"
                        }}                                     
                        label={translate("pages.pedidos.itens.fields.descItemUnidade")}
                        name="descItemUnidade"
                        disabled={!canEdit}
                    />       
                    <TextField
                        {...register("valorPrecoItemUnidade", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorPrecoItemUnidade}
                        helperText={(errors as any)?.valorPrecoItemUnidade?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}     
                        label={translate("pages.pedidos.itens.fields.valorPrecoItemUnidade")}
                        name="valorPrecoItemUnidade"
                        value={itempreco} 
                        onChange={handleChange}
                        disabled={!canEdit}
                    />
                    {/* <TextField
                        {...register("valorDescontoItem", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorDescontoItem}
                        helperText={(errors as any)?.valorDescontoItem?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}     
                        label={translate("pages.pedidos.itens.fields.valorDescontoItem")}
                        name="valorDescontoItem"
                    />                    
                    <TextField
                        {...register("valorItemLiquido", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemLiquido}
                        helperText={(errors as any)?.valorItemLiquido?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "170px",
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}     
                        label={translate("pages.pedidos.itens.fields.valorItemLiquido")}
                        name="valorItemLiquido"
                    />   */}
                    <TextField
                        {...register("valorItemTotal", {
                            required: "This field is required",
                            valueAsNumber: true,
                        })}
                        error={!!(errors as any)?.valorItemTotal}
                        helperText={(errors as any)?.valorItemTotal?.message}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        style={{
                            width: "220px"
                        }}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>
                          }}                             
                        label={translate("pages.pedidos.itens.fields.valorItemTotal")}
                        name="valorItemTotal"
                        value={itemprecototal} 
                        disabled={!canEdit}
                    />    
                </Box>
            </Edit>
        </Drawer>
    );
};