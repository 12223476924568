import React, {  useEffect, useState } from "react";
import { Edit, useAutocomplete,useDataGrid, List, EditButton, DeleteButton, NumberField } from "@refinedev/mui";
import { Box, TextField, Autocomplete, Stack, Typography, Accordion, AccordionSummary, AccordionDetails, Modal, Button, IconButton, 
    InputAdornment, TableContainer, Paper, Table, TableRow, TableCell, TableBody, 
    Grid} from "@mui/material";
import { useForm, useModalForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate, usePermissions, useOne, useMany } from "@refinedev/core";
import { Controller } from "react-hook-form";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { HttpError } from "@refinedev/core";

import { CreateItemPedidoDrawer,EditItemPedidoDrawer } from "../../components/itempedido";
import { IEtapaPedido, IItemPedido,Nullable, IAppPermission } from "../../interfaces";

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { StatusPedido } from "../../components";

import {DropzoneAreaBase} from "../../../node_modules/react-mui-dropzone/dist/index";
import type { FileObject as FileObjectDropzone } from "../../../node_modules/react-mui-dropzone/dist/";

import axios, { AxiosRequestConfig } from "axios";
import { AppSettings } from "../../constants/appsettings";
import FileSaver from 'file-saver'
import { useParams } from 'react-router-dom';

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { getAuth, getToken, listObrasUsuario, listPerfisObrasRestrita } from "../../utils/userspermissions";

export const PedidoEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();    
    const { id } = useParams();
    const auth =   getAuth();
    const obrasUsuario = listObrasUsuario();

    // const getToPath = useGetToPath();
    // getToPath({
    //     resource: "itens",
    //     action: "edit",
    //     meta: {
    //       idpedido: 1,
    //       id: 2,
    //     },
    //   })

    let {permissionsData} : IAppPermission = {};
    permissionsData = usePermissions()?.data;

    const {
        saveButtonProps:{onClick},
        refineCore: {  
                        queryResult
                    },
        register,
        control,
        formState: { errors }
    } = useForm();

    const [expanded, setExpanded] = React.useState<any | null>(null);
    //const [hidden, setHidden] = React.useState<any | null>(null);

    const handleChange__Accordion = (panel:any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
      };    

    //   const handleChange__ProxStatus = (status:any) => (event: any, isExpanded: any) => {
    //     setExpanded(isExpanded ? panel : false);
    //   };          

    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {    
        const token = getToken();
        let tokenkey = AppSettings.API_TOKEN_KEY;
        if (token) {
          if (request.headers) {
            request.headers[tokenkey] = `${token}`;
            //request.headers["Content-Type"] = "multipart/form-data";
            
          } else {
            request.headers = {
                tokenkey: `${token}`,
            };
          }
        }    
        return request; 
      });


    const [files, setFiles] = useState<any>([])
    const [loadingfiles, setLoadingfiles] = useState(false);
    const [dhpedido, setDhpedido] = useState(new Date());
    const [dtinicio, setDtinicio] = useState(new Date());
    const [dttermino, setDttermino] = useState(new Date());
    const [pedidosdata, setPedidosdata] = useState<any>({});


    useEffect(() => {
        if(!loadingfiles){
            setLoadingfiles(true);
            getUploadedFiles();   
        }        

        if(queryResult?.isFetched){
            setPedidosdata(queryResult?.data?.data);
            setDhpedido(queryResult?.data?.data?.dhPedido);
            setDtinicio(queryResult?.data?.data?.dtInicio);
            setDttermino(queryResult?.data?.data?.dtTermino);
        }

     }, [queryResult?.isFetched]); 


    //const{data: permissionsdata,isLoading: permissionsIsLoading, error: permissionsError} = usePermissions()


    const getUploadedFiles = () => {      
        axiosInstance.get(`${AppSettings.API_URL}/pedidos/${id}/anexos`, {
            headers:{
                "Content-Type": "application/json"
            },
        })
        .then((res) => {
            res.data.forEach((file: any) => {
                const originalFileName = file.descNomeOriginalArquivo;

                axiosInstance.get (`${AppSettings.API_URL}/pedidos/${file.idPedido.toString()}/anexos/download/originalname?originalname=${encodeURIComponent(originalFileName)}`, {
                    headers:{
                        "Content-Type": "application/json"
                    },
                    responseType: 'blob'
                })
                .then((res) => {
                    // handle the response
                    //console.log(res);        
                    const blob = new Blob([res.data]);
                    const fileObject = new File([blob],originalFileName,{
                            lastModified:new Date().getTime(),
                            type: blob.type,
                        })

                    const reader = new FileReader();
                    
                    reader.onloadend = ()=>{
                        const base64 = reader.result;

                        const fileDropZone:FileObjectDropzone = {
                            file: fileObject,
                            data: base64
                        }
                
                        setFiles((old: any) => [...old, fileDropZone])
                    }
                    reader.onerror = (err) => {
                        alert(err);
                    }
                    reader.readAsDataURL(blob);
                })
                .catch((err) => {
                    // handle errors
                    //console.log(err);
                    alert(err);
                });          
            });
        })
        .catch((err) => {
            // handle errors
            //console.log(err);
            alert(err);
        });      
     }    

    const handleAddDrop = (newFiles: any) => {
        setFiles([].concat(files as any,newFiles));

        const token = getToken();
        const tokenkey = AppSettings.API_TOKEN_KEY;

        newFiles.forEach((file: { file:  any; }) => {
            file.file.originalname = file.file.name
            const formData = new FormData();
            formData.append('_method', 'post');
            formData.append("file",file.file);
            formData.append("idUsuarioInclusao",auth.id);
            formData.append("idUsuarioAlteracao",auth.id);
            
            axiosInstance.post(`${AppSettings.API_URL}/pedidos/${pedidosdata?.id?.toString()}/anexos`, formData,{
                headers:{
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((res) => {
                // handle the response
               //console.log(res);
              })
              .catch((err) => {
                // handle errors
                //console.log(err);
                alert(err);
              });                                
        });     
      };
      
      
      const handleDelete = (deleted: any) => {
        setFiles(files.filter((f: any) => f !== deleted));
        const file = deleted;

        axiosInstance.delete(`${AppSettings.API_URL}/pedidos/${pedidosdata?.id?.toString()}/anexos?originalname=${encodeURIComponent(file.file.name)}`, {
            headers:{
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        .then((res) => {
            // handle the response
            //console.log(res);
            })
            .catch((err) => {
            // handle errors
            //console.log(err);
            alert(err);
            });                                
      };

      const handleFileClick = (e: { currentTarget: { innerText: any; }; })=>{
        const fileName = e.currentTarget.innerText;

        axiosInstance.get (`${AppSettings.API_URL}/pedidos/${pedidosdata?.id?.toString()}/anexos/download/originalname?originalname=${encodeURIComponent(fileName)}`, {
            headers:{
                "Content-Type": "application/json"
            },
            responseType: 'blob'
        })
        .then((res) => {
                // handle the response
                //console.log(res);

                const blob = new Blob([res.data],{type:res.data.type});

                if(!!String(fileName).match(/.pdf|.jpg|.jpeg|.png|.gif/gi)){
                    const pdfUrl = URL.createObjectURL(blob);
                    window.open(pdfUrl, "_blank");                    
                    URL.revokeObjectURL(pdfUrl);
                }else{
                    FileSaver.saveAs(blob,`${fileName}`);
                }
            
            })
            .catch((err) => {
                // handle errors
                //console.log(err);
                alert(err);
            });          
      }
   
    const { autocompleteProps: obraAutocompleteProps } = useAutocomplete({
        resource: "obras",
        defaultValue: pedidosdata?.idObra,
        queryOptions: { enabled: queryResult?.isFetched },   
        filters:[
            {
                field: "id",
                operator: "in",
                value: (listPerfisObrasRestrita.includes(auth.roles)?obrasUsuario:[])
            },
            {
                field: "flAtivo",
                operator: "eq",
                value: 1
            },
        ]        
    });

    const { autocompleteProps: deptoAutocompleteProps } = useAutocomplete({
        resource: "departamentos",
        defaultValue: pedidosdata?.idDepartamento,     
        queryOptions: { enabled: queryResult?.isFetched },      
    });

    const { autocompleteProps: statusAutocompleteProps } = useAutocomplete({
        resource: "status",
        defaultValue: pedidosdata?.idStatus,
        queryOptions: { enabled: queryResult?.isFetched },   
    });    
 
    const { dataGridProps } = useDataGrid<IItemPedido>({ 
        resource: `pedidos/${id}/itens`,   
        queryOptions: { enabled: queryResult?.isFetched}     
                    
    });

    const { autocompleteProps: workflowAutocompleteProps } = useAutocomplete({
        resource: "workflows",
        defaultValue: pedidosdata?.status?.workflows_statusatual[0]?.id,
        queryOptions: { enabled: queryResult?.isFetched },  
        filters:[{
            field:"idStatusAtual",
            operator:"eq",
            value: pedidosdata?.idStatus
        }]
    });       

    const columns = React.useMemo<GridColDef<IItemPedido>[]>(
        () => [
            {
                field: "id",
                flex:0,
                headerName: translate("pages.pedidos.itens.fields.id"),
                type: "number",
                minWidth: 10,
                filterable:false,
            },                                           
            {
                field: "descItemPedido",
                flex: 1,
                headerName: translate("pages.pedidos.itens.fields.descItemPedido"),
                minWidth: 150,
                filterable:false,
            },  
            {
                field: "codigoDiagramaOrdem",
                flex: 1,
                headerName: translate("pages.pedidos.itens.fields.codigoDiagramaOrdem"),
                minWidth: 50,
            },             
            {
                field: "codigoTributo",
                flex: 0,
                headerName: translate("pages.pedidos.itens.fields.codigoTributo"),
                minWidth: 120,
                filterable:false,
            },              
            {
                field: "valorItemTotal",
                flex: 0,
                headerName: translate("pages.pedidos.itens.fields.valorItemTotal"),
                type: "number",
                minWidth: 150,
                filterable:false,       
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(0+value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },                         
            },                                 
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {            
                    return (                        
                        <>
                            <EditButton 
                                hideText 
                                recordItemId={row.id}
                                //onClick={canEdit ? () => showEditDrawer(row.id) : ()=>{return false}} 
                                onClick={() => showEditDrawer(row.id)} 
                                //disabled={!canEdit}
                                disabled={false}
                            />
                            <DeleteButton 
                                hideText 
                                recordItemId={row.id} 
                                resource={"pedidos/"+row.idPedido.toString()+"/itens"}     
                                disabled={!canEdit}
                                //disabled={false}
                                onSuccess={(value) => {                                    
                                    queryResult?.refetch();
                                } }
                            />                            
                        </>
                    )
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,           
                filterable:false, 
            },            

        ],
        [queryResult, translate],
    );    

 
    const pedidosData = queryResult?.data?.data;
    const itens: IItemPedido[] = queryResult?.data?.data?.itens || [];
    const etapas: IEtapaPedido[] = queryResult?.data?.data?.etapas || [];

    const { data: autorEtapa, isLoading: autorEtapaIsLoading } = useMany({
        resource: "usuarios",
        ids: queryResult?.data?.data?.etapas?.map((usuario: any) => usuario?.idUsuarioInclusao) ?? [],
        queryOptions: { enabled: queryResult?.isFetched }      
    });    

    const { data: statusAntEtapa, isLoading: statusAntIsLoading } = useMany({
        resource: "status",
        ids: queryResult?.data?.data?.etapas?.map((status: any) => status?.idStatusAnterior) ?? [],
        queryOptions: { enabled: queryResult?.isFetched }        
    });       

    const { data: statusNovoEtapa, isLoading: statusNovoIsLoading } = useMany({
        resource: "status",
        ids: queryResult?.data?.data?.etapas?.map((status: any) => status?.idStatusNovo) ?? [],
        queryOptions: { enabled: queryResult?.isFetched }       
    }); 

    const CustomFooterItens = () => (
        <Box>
            <Stack direction="row" spacing={4} justifyContent="flex-end" p={1} paddingTop={"4px"} paddingBottom={"4px"}>
                <Typography  fontWeight={700} fontSize={"0.8rem"}>
                    {translate("pages.pedidos.fields.valorAdicional__footer")}
                </Typography>
                <Typography fontSize={"0.8rem"}>{parseFloat(pedidosData?.valorAdicional).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography>
            </Stack>                
            <Stack direction="row" spacing={4} justifyContent="flex-end" p={1} paddingTop={"8px"} paddingBottom={"4px"}>
                <Typography fontWeight={700} fontSize={"0.8rem"}>
                    {translate("pages.pedidos.fields.valorAtualContrato__footer")}
                </Typography>
                <Typography fontSize={"0.8rem"}>{parseFloat(pedidosData?.valorAtualContrato).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography>
            </Stack> 
            <Stack direction="row" spacing={4} justifyContent="flex-end" p={1} paddingTop={"4px"} paddingBottom={"4px"}>
                <Typography sx={{ color: "primary.main" }} fontWeight={700}>
                    {translate("pages.pedidos.fields.valorNovoContrato__footer")}
                </Typography>
                <Typography>{parseFloat(pedidosData?.valorNovoContrato).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Typography>
            </Stack>                         
        </Box>
    );


    const styleHistoryModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #221f1f',
        boxShadow: 24,
        p: 4,
    };
    const [openHistoryModal, setOpenHistoryModal] = React.useState(false);
    const handleOpen__HistoryModal = () => setOpenHistoryModal(true);
    const handleClose__HistoryModal = () => setOpenHistoryModal(false);

    const HistoryModal = () => (
        <Modal
            open={openHistoryModal}
            onClose={handleClose__HistoryModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleHistoryModal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Modal Header
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Modal content
            </Typography>
            </Box>
        </Modal>
    );        

    const createDrawerFormProps = useModalForm<
        IItemPedido,
        HttpError,
        Nullable<IItemPedido>
    >({
        refineCoreProps: { 
            action: "create",
            resource: `pedidos/${id}/itens`,
            meta:{
                idStatus:"2"
            },
            mutationMeta:{
                idStatus:"2"
            },
            liveParams:{
                idStatus:"2"
            }                 
        },      
        syncWithLocation: true,  
        // defaultValues: {
        //      nuInteracao: pedidosData?.nuInteracao||4                         
        // }
    });    
    const {
        modal: { show: showCreateDrawer }
    } = createDrawerFormProps;


    const editDrawerFormProps = useModalForm<IItemPedido, HttpError, Nullable<IItemPedido>>(
        {
            refineCoreProps: { 
                action: "edit",
                resource: `pedidos/${id}/itens`,
                //id: id
            },
            syncWithLocation: true,            
        }
    );   
    const {
        modal: { show: showEditDrawer},
    } = editDrawerFormProps;

    // const canView = ():boolean=>{
    //     return  (
    //         queryResult?.isFetched ? 
    //             (permissionsData?.status_profile?.find(
    //                 (item: { idStatus: any; })=>item.idStatus===queryResult?.data?.data?.idStatus
    //                 )
    //             ?.acessoLeitura)
    //             :(false)
    //     )  
    // };

    const canView = React.useMemo(():boolean=>{
        return permissionsData?.status_profile?.some(
            (item: { idStatus: any; acessoLeitura: boolean }) =>
                item.idStatus === queryResult?.data?.data?.idStatus && item.acessoLeitura
        );    
    },[queryResult]);        

    // const canEdit = ():boolean=>{
    //     return  (
    //             queryResult?.isFetched ? 
    //                 (permissionsData?.status_profile?.find(
    //                         (item: { idStatus: any; })=>item.idStatus===queryResult?.data?.data?.idStatus
    //                     )
    //                 ?.acessoEdicao) || queryResult?.data?.data?.obra?.flAtivo
    //                 :(false)
    //         )        
    // };

    const canEdit = React.useMemo(():boolean=>{
        return (
            permissionsData?.status_profile?.some(
                (item: { idStatus: any; acessoEdicao: boolean }) =>
                    item.idStatus === queryResult?.data?.data?.idStatus &&
                    item.acessoEdicao
            ) &&
            queryResult?.data?.data?.obra?.flAtivo
        );          
    },[queryResult]);    

    // const canApprove = ():boolean=>{
    //     return(
    //             queryResult?.isFetched ? 
    //                 (permissionsData?.status_profile?.find(
    //                     (item: { idStatus: any; })=>item.idStatus===queryResult?.data?.data?.idStatus
    //                     )
    //                 ?.acessoAprovacao)
    //                 :(false)
    //         )        
    // };

    const canApprove = React.useMemo(():boolean=>{
        return (
            permissionsData?.status_profile?.some(
                (item: { idStatus: any; acessoAprovacao: boolean }) =>
                    item.idStatus === queryResult?.data?.data?.idStatus &&
                    item.acessoAprovacao
            ) &&
            queryResult?.data?.data?.obra?.flAtivo
        );
    },[queryResult]);  

    return (
        <Edit 
            saveButtonProps={{ 
                                disabled: !canApprove,
                                onClick: onClick,
                             }}
            
        >
            <Accordion 
                expanded={expanded === 'panel1'} 
                onChange={handleChange__Accordion('panel1')}
                sx={{marginBottom:"5px"}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlinedIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography variant="subtitle1" sx={{ color: "primary.main" }} fontWeight={expanded === 'panel1' ? 700 : 400}>{translate("pages.pedidos.titles.accordion")}</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Box
                        component="form"
                        // sx={{ display: "flex", flexDirection: "column" }}
                        autoComplete="off"
                    >
                        <Grid container columns={{ xs: 4, sm: 8, md: 12, lg: 24, xl:24}} spacing={2}>
                            <Grid item xs={4} sm={8} md={2} lg={3} xl={3}>
                                    <TextField
                                        {...register("id", {
                                            required: "This field is required",
                                            valueAsNumber: true,
                                        })}
                                        error={!!(errors as any)?.id}
                                        helperText={(errors as any)?.id?.message}
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        type="number"
                                        label={translate("pages.pedidos.fields.id")}
                                        name="id"
                                        disabled
                                        tabIndex={1}
                                    />                            
                            </Grid>

                            <Grid item xs={4} sm={8} md={3} lg={5} xl={5}>
                                <TextField
                                    {...register("dhPedido", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.dhPedido}
                                    helperText={(errors as any)?.dhPedido?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true  }}
                                    label={translate("pages.pedidos.fields.dhPedido")}
                                    name="dhPedido"
                                    value={dayjs(dhpedido).format("DD/MM/YYYY HH:mm:ss")}
                                    disabled      
                                    tabIndex={2}     
                                />
                            </Grid>


                            <Grid item xs={4} sm={8} md={7} lg={16} xl={16}>
                                <Controller
                                        control={control}
                                        name="idObra"
                                        rules={{ required: translate("validations.requiredField") }}
                                        // eslint-disable-next-line
                                        defaultValue={null as any}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...obraAutocompleteProps}
                                                {...field}
                                                onChange={(_, value) => {
                                                    field.onChange(value);
                                                }}
                                                getOptionLabel={(item) => {
                                                    if(typeof(item) === 'object'){
                                                        return(
                                                            item.id + ' - ' +
                                                            obraAutocompleteProps?.options?.find(
                                                                (p) =>
                                                                    p?.id?.toString() ===
                                                                    item?.id?.toString(),
                                                            )?.nomeObra ?? ""
                                                        );
                                                    }else{
                                                        return(
                                                            item + ' - ' +
                                                            obraAutocompleteProps?.options?.find(
                                                                (p) =>
                                                                    p?.id?.toString() ===
                                                                    item?.toString(),
                                                            )?.nomeObra ?? ""
                                                        );
                                                    }
                                                }}
                                                isOptionEqualToValue={(option, value) =>
                                                    value === undefined ||
                                                    option?.id?.toString() === value?.toString()
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translate("pages.pedidos.fields.idObra")}
                                                        margin="normal"
                                                        variant="outlined"
                                                        error={!!(errors as any)?.idObra}
                                                        helperText={
                                                            (errors as any)?.idObra?.message
                                                        }
                                                        required
                                                    />
                                                )}
                                                //disabled={!canEdit} 
                                                disabled
                                                tabIndex={3}     
                                            />
                                        )}
                                />                              
                            </Grid>


                            <Grid item xs={4} sm={8} md={3} lg={3} xl={3}>
                                <TextField
                                    {...register("nuInteracao", {
                                        required: "This field is required",
                                        valueAsNumber: true,
                                    })}
                                    error={!!(errors as any)?.nuInteracao}
                                    helperText={(errors as any)?.nuInteracao?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="number"                   
                                    label={translate("pages.pedidos.fields.nuInteracao")}
                                    name="nuInteracao"
                                    disabled
                                    tabIndex={4}     
                                />                             
                            </Grid>


                            {/* <Controller
                                control={control}
                                name="idDepartamento"
                                rules={{ required: translate("validations.requiredField") }}
                                // eslint-disable-next-line
                                defaultValue={null as any}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...deptoAutocompleteProps}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            if(typeof(item) === 'object'){
                                                return(
                                                    item.id + ' - ' +
                                                    deptoAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.id?.toString(),
                                                    )?.nomeDepartamento ?? ""
                                                );
                                            }else{
                                                return(
                                                    item + ' - ' +
                                                    deptoAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.toString(),
                                                    )?.nomeDepartamento ?? ""
                                                );
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            value === undefined ||
                                            option?.id?.toString() === value?.toString()
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate("pages.pedidos.fields.idDepartamento")}
                                                margin="normal"
                                                variant="outlined"
                                                error={!!(errors as any)?.idDepartamento}
                                                helperText={
                                                    (errors as any)?.idDepartamento?.message
                                                }
                                                required
                                            />
                                        )}
                                        disabled={!canEdit} 
                                    />
                                )}
                            />    */}

                            <Grid item xs={4} sm={8} md={9} lg={21} xl={21}>
                                <Controller
                                    control={control}
                                    name="idStatus"
                                    rules={{ required: translate("validations.requiredField") }}
                                    // eslint-disable-next-line
                                    defaultValue={null as any}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...statusAutocompleteProps}
                                            {...field}
                                            onChange={(_, value) => {
                                                field.onChange(value);
                                            }}
                                            disabled
                                            tabIndex={5}     
                                            getOptionLabel={(item) => {
                                                if(typeof(item) === 'object'){
                                                    return(
                                                        item.id + ' - ' +
                                                        statusAutocompleteProps?.options?.find(
                                                            (p) =>
                                                                p?.id?.toString() ===
                                                                item?.id?.toString(),
                                                        )?.nomeStatus ?? ""
                                                    );
                                                }else{
                                                    return(
                                                        item + ' - ' +
                                                        statusAutocompleteProps?.options?.find(
                                                            (p) =>
                                                                p?.id?.toString() ===
                                                                item?.toString(),
                                                        )?.nomeStatus ?? ""
                                                    );
                                                }
                                            }}
                                            isOptionEqualToValue={(option, value) =>
                                                value === undefined ||
                                                option?.id?.toString() === value?.toString()
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={translate("pages.pedidos.fields.idStatus")}
                                                    margin="normal"
                                                    variant="outlined"
                                                    error={!!(errors as any)?.idStatus}
                                                    helperText={
                                                        (errors as any)?.idStatus?.message
                                                    }
                                                    required
                                                />
                                            )}
                                        />
                                    )}
                                />                              
                            </Grid>

    

                            {/*
                                DatePicker component is not included in "@refinedev/mui" package.
                                To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                            */}

                            <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                                <TextField
                                    {...register("numeroContrato", {
                                        required: "This field is required",
                                        valueAsNumber: true,
                                    })}
                                    error={!!(errors as any)?.numeroContrato}
                                    helperText={(errors as any)?.numeroContrato?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    label={translate("pages.pedidos.fields.numeroContrato")}
                                    name="numeroContrato"
                                    disabled={!canEdit} 
                                    tabIndex={6}     
                                />                            
                            </Grid>

                            <Grid item xs={4} sm={8} md={6} lg={12} xl={12} >
                                <TextField
                                    {...register("numeroPedido", {
                                        required: "This field is required",
                                        valueAsNumber: true,
                                    })}
                                    error={!!(errors as any)?.numeroPedido}
                                    helperText={(errors as any)?.numeroPedido?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    label={translate("pages.pedidos.fields.numeroPedido")}
                                    name="numeroPedido"
                                    disabled={!canEdit} 
                                    tabIndex={7}     
                                />
                            </Grid>

                            <Grid item xs={4} sm={8} md={3} lg={6} xl={6}>
                                <TextField
                                    {...register("codigoInternoFornecedor", {
                                        required: "This field is required",
                                        valueAsNumber: true,
                                    })}
                                    error={!!(errors as any)?.codigoInternoFornecedor}
                                    helperText={
                                        (errors as any)?.codigoInternoFornecedor?.message
                                    }
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="number"
                                    label={translate("pages.pedidos.fields.codigoInternoFornecedor")}
                                    name="codigoInternoFornecedor"
                                    disabled={!canEdit} 
                                    tabIndex={8}     
                                />
                            </Grid>

                            <Grid item xs={4} sm={8} md={9} lg={18} xl={18}>
                                <TextField
                                    {...register("nomeFornecedor", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.nomeFornecedor}
                                    helperText={(errors as any)?.nomeFornecedor?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    label={translate("pages.pedidos.fields.nomeFornecedor")}
                                    name="nomeFornecedor"
                                    disabled={!canEdit} 
                                    tabIndex={9}  
                                />
                            </Grid>


                            {/* <TextField
                                {...register("numeroTelefoneFornecedor", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.numeroTelefoneFornecedor}
                                helperText={
                                    (errors as any)?.numeroTelefoneFornecedor?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={translate("pages.pedidos.fields.numeroTelefoneFornecedor")}
                                name="numeroTelefoneFornecedor"
                                disabled={!canEdit} 
                            />
                            <TextField
                                {...register("nomeContatoFornecedor", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.nomeContatoFornecedor}
                                helperText={(errors as any)?.nomeContatoFornecedor?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label={translate("pages.pedidos.fields.nomeContatoFornecedor")}
                                name="nomeContatoFornecedor"
                                disabled={!canEdit} 
                            /> */}

                            {/*
                                DatePicker component is not included in "@refinedev/mui" package.
                                To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                            */}
                            {/* <TextField
                                {...register("valorSubtotalIncorporator", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.valorSubtotalIncorporator}
                                helperText={
                                    (errors as any)?.valorSubtotalIncorporator?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={translate(
                                    "pages.pedidos.fields.valorSubtotalIncorporator",
                                )}
                                name="valorSubtotalIncorporator"
                                disabled
                            />
                            <TextField
                                {...register("valorDescontoIncorporator", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.valorDescontoIncorporator}
                                helperText={
                                    (errors as any)?.valorDescontoIncorporator?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={translate(
                                    "pages.pedidos.fields.valorDescontoIncorporator",
                                )}
                                name="valorDescontoIncorporator"
                                disabled
                            />
                            <TextField
                                {...register("valorTotalIncorporator", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.valorTotalIncorporator}
                                helperText={
                                    (errors as any)?.valorTotalIncorporator?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={translate("pages.pedidos.fields.valorTotalIncorporator")}
                                name="valorTotalIncorporator"
                                disabled
                            />
                            <TextField
                                {...register("nuPercentualMaoObra", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.nuPercentualMaoObra}
                                helperText={(errors as any)?.nuPercentualMaoObra?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "100px"
                                // }}     
                                // sx={{
                                //     paddingX: { xs: 3, md: 2 },
                                //     paddingY: { xs: 2, md: 3 },
                                //     my: 0.5,
                                // }}                         
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}                                                         
                                label={translate("pages.pedidos.fields.nuPercentualMaoObra")}
                                name="nuPercentualMaoObra"
                                disabled={!canEdit} 
                            />
                            <TextField
                                {...register("valorMaoObra", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.valorMaoObra}
                                helperText={(errors as any)?.valorMaoObra?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "150px"
                                // }}         
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                }}                                                      
                                label={translate("pages.pedidos.fields.valorMaoObra")}
                                name="valorMaoObra"
                                disabled={!canEdit} 
                            />
                            <TextField
                                {...register("nuPercentualMaterial", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.nuPercentualMaterial}
                                helperText={(errors as any)?.nuPercentualMaterial?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "100px"
                                // }}     
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}                                              
                                label={translate("pages.pedidos.fields.nuPercentualMaterial")}
                                name="nuPercentualMaterial"
                                disabled={!canEdit} 
                            />
                            <TextField
                                {...register("valorMaterial", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.valorMaterial}
                                helperText={(errors as any)?.valorMaterial?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "150px"
                                // }}         
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                }}                         
                                label={translate("pages.pedidos.fields.valorMaterial")}
                                name="valorMaterial"
                                disabled={!canEdit} 
                            /> */}
                            
                            <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                                {/*
                                    DatePicker component is not included in "@refinedev/mui" package.
                                    To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                                */}
                                <TextField
                                    {...register("dtInicio", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.dtInicio}
                                    helperText={(errors as any)?.dtInicio?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label={translate("pages.pedidos.fields.dtInicio")}
                                    name="dtInicio"
                                    value={dayjs(dtinicio).format("DD/MM/YYYY")}
                                    disabled={!canEdit} 
                                    tabIndex={10}  
                                />
                            </Grid>
                            <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                                {/*
                                    DatePicker component is not included in "@refinedev/mui" package.
                                    To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                                */}
                                <TextField
                                    {...register("dtTermino", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.dtTermino}
                                    helperText={(errors as any)?.dtTermino?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label={translate("pages.pedidos.fields.dtTermino")}
                                    name="dtTermino"
                                    value={dayjs(dttermino).format("DD/MM/YYYY")}
                                    disabled={!canEdit} 
                                    tabIndex={11}  
                                />                            
                            </Grid>                        
                            


                            {/* <TextField
                                {...register("numeroPedidoSuprimentos", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.numeroPedidoSuprimentos}
                                helperText={
                                    (errors as any)?.numeroPedidoSuprimentos?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                label={translate("pages.pedidos.fields.numeroPedidoSuprimentos")}
                                name="numeroPedidoSuprimentos"
                                disabled={!canEdit} 
                            />
                            {/*
                                DatePicker component is not included in "@refinedev/mui" package.
                                To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                            */}
                            {/* <TextField
                                {...register("dtEmissaoSuprimentos", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.dtEmissaoSuprimentos}
                                helperText={(errors as any)?.dtEmissaoSuprimentos?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={translate("pages.pedidos.fields.dtEmissaoSuprimentos")}
                                name="dtEmissaoSuprimentos"
                                disabled={!canEdit} 
                            />  */}

                            {/*
                                DatePicker component is not included in "@refinedev/mui" package.
                                To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                            */}
                        
                        
                        <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                            <TextField
                                {...register("nuPercentualCaucao", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.nuPercentualCaucao}
                                helperText={(errors as any)?.nuPercentualCaucao?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "40%"
                                // }}   
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}                          
                                label={translate("pages.pedidos.fields.nuPercentualCaucao")}
                                name="nuPercentualCaucao"
                                disabled={!canEdit} 
                                tabIndex={12}  
                            /> 
                        </Grid>
                        <Grid item xs={4} sm={8} md={6} lg={12} xl={12}>
                            <TextField
                                {...register("valorAtualContrato", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.valorAtualContrato}
                                helperText={(errors as any)?.valorAtualContrato?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "200px"
                                // }}         
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                }}                                 
                                label={translate("pages.pedidos.fields.valorAtualContrato")}
                                name="valorAtualContrato"
                                disabled={!canEdit} 
                                tabIndex={13}  
                            />                        
                        </Grid>

                        

                            {/* <TextField
                                {...register("valorAdicional", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.valorAdicional}
                                helperText={(errors as any)?.valorAdicional?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "150px"
                                // }}         
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                }}  
                                label={translate("pages.pedidos.fields.valorAdicional")}
                                name="valorAdicional"
                                disabled={!canEdit} 
                            /> */}
                            {/*
                                DatePicker component is not included in "@refinedev/mui" package.
                                To use a <DatePicker> component, you can follow the official documentation for Material UI.

                                Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
                            */}
                            {/* <TextField
                                {...register("valorNovoContrato", {
                                    required: "This field is required",
                                })}
                                error={!!(errors as any)?.valorNovoContrato}
                                helperText={(errors as any)?.valorNovoContrato?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                // style={{
                                //     width: "200px"
                                // }}         
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>
                                }}                          
                                label={translate("pages.pedidos.fields.valorNovoContrato")}
                                name="valorNovoContrato"
                                disabled={!canEdit} 
                            /> */}
                            {/* <TextField
                                {...register("numeroContratoIncorporator", {
                                    required: "This field is required",
                                    valueAsNumber: true,
                                })}
                                error={!!(errors as any)?.numeroContratoIncorporator}
                                helperText={
                                    (errors as any)?.numeroContratoIncorporator?.message
                                }
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="number"                       
                                label={translate(
                                    "pages.pedidos.fields.numeroContratoIncorporator",
                                )}
                                name="numeroContratoIncorporator"
                                disabled={!canEdit} 
                            /> */}
    
                            <Grid item xs={4} sm={8} md={12} lg={24} xl={24}>
                                <TextField
                                    {...register("observPedido", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.observPedido}
                                    helperText={(errors as any)?.observPedido?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    multiline
                                    minRows={3}
                                    maxRows={3}
                                    label={translate("pages.pedidos.fields.observPedido")}
                                    name="observPedido"
                                    inputProps={{
                                        maxLength: 120,
                                    }}                            
                                    disabled={!canEdit} 
                                    tabIndex={14}  
                                />
                            </Grid>
                        </Grid>
                    </Box>                        
                </AccordionDetails>
            </Accordion>
            <Accordion 
                expanded={expanded === 'panel2'} 
                onChange={handleChange__Accordion('panel2')}
                sx={{marginBottom:"5px"}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlinedIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography variant="subtitle1" sx={{ color: "primary.main" }} fontWeight={expanded === 'panel2' ? 700 : 400}>{translate("pages.pedidos.itens.titles.accordion")}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding:"0"
                    }}
                >
                    <List                     
                        // wrapperProps={{
                        //     sx:{},
                            
                        // }}                        
                        headerProps={{                            
                            title:   "",//translate("pages.pedidos.itens.titles.grid"),
                            titleTypographyProps:{
                                    typography: "subtitle2",
                                    fontWeight:"700"
                                },                                                        
                        }}                        
                        breadcrumb={false}
                        createButtonProps={{
                            hideText:true,
                            onClick: () => showCreateDrawer()
                        }}
                        canCreate={canEdit}
                        >

                        <DataGrid                             
                            {...dataGridProps} 
                            columns={columns} 
                            //rows={itens}
                            autoHeight 
                            hideFooterPagination
                            slots={{
                                footer:CustomFooterItens
                            }}  
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}    
                        />
                    </List>

                </AccordionDetails>
            </Accordion>
            <Accordion 
                expanded={expanded === 'panel3'} 
                onChange={handleChange__Accordion('panel3')}
                sx={{marginBottom:"5px"}}    
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlinedIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography variant="subtitle1" mr={1} sx={{ color: "primary.main"}} fontWeight={expanded === 'panel3' ? 700 : 400}>
                        {translate("pages.pedidos.titles.accordion-attachment")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding:"16px"
                    }}                
                >

                <DropzoneAreaBase    
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview={true}
                    previewGridProps={{
                                        container: { spacing: 1, direction: 'row'},
                                        item:{onClick:(e)=>handleFileClick(e)}
                                    }}                                
                    acceptedFiles={['image/jpeg,image/gif,image/png,image/jpg,application/pdf,application/vnd.ms-excel,application/vnd.ms-word','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/docx','application/msword','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    maxFileSize={2*1024*1024}
                    filesLimit={10}
                    previewText={translate("pages.pedidos.anexos.preview-text")}
                    dropzoneText={translate("pages.pedidos.anexos.dropzone-text")}
                    getFileLimitExceedMessage={(filesLimit) => translate("pages.pedidos.anexos.exceedlimitmessage")}
                    getFileAddedMessage={(fileName:string) => canEdit ? `${fileName}`+ translate("pages.pedidos.anexos.addedmessage"):translate("pages.pedidos.anexos.addedmessagedenied")}
                    getFileRemovedMessage={(fileName:string) => canEdit ? `${fileName}` + translate("pages.pedidos.anexos.removedmessage"):translate("pages.pedidos.anexos.removedmessagedenied")}
                    getDropRejectMessage={
                        (rejectedFile,acceptedFiles,maxFileSize) => {
                            if(rejectedFile.size > maxFileSize){
                                return `${rejectedFile.name}` + translate("pages.pedidos.anexos.exceedmessage");                            
                            }else{
                                return `${rejectedFile.name}` + translate("pages.pedidos.anexos.rejectmessage");                            
                            }
                        }  
                    }
                    fileObjects={files}
                    onAdd={canEdit ? handleAddDrop : ()=>{return false} }
                    //onAdd={handleAddDrop}
                    onDelete={canEdit ? handleDelete: ()=>{return false}}                        
                />

                </AccordionDetails>
            </Accordion>

            <Accordion 
                expanded={expanded === 'panel4'} 
                onChange={handleChange__Accordion('panel4')}
                sx={{marginBottom:"5px"}}    
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlinedIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography variant="subtitle1" mr={1} sx={{ color: "primary.main"}} fontWeight={expanded === 'panel4' ? 700 : 400}>
                        {translate("pages.pedidos.etapas.titles.accordion")}
                    </Typography>
                    <StatusPedido status={pedidosdata?.idStatus}/>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding:"0"
                    }}                
                >
                    <List                     
                        headerProps={{                            
                            title:   "",
                            titleTypographyProps:{
                                    typography: "subtitle2",
                                    fontWeight:"700"
                                },                                                        
                        }}                        
                        breadcrumb={false}
                        canCreate={false}
                        headerButtons={({defaultButtons}) =>(
                            <>
                                {defaultButtons}
                                <Button type="button" onClick={handleOpen__HistoryModal}>{translate("pages.pedidos.etapas.actions.history")}</Button>
                            </>
                        )}
                        >
                        <Box
                            component="form"
                            sx={{ display: "flex", flexDirection: "column", marginTop: "-36px" }}
                            autoComplete="off"
                        >                           
                            <Controller
                                control={control}
                                name="idProximoStatus"
                                rules={{ required: translate("validations.requiredField") }}
                                // eslint-disable-next-line
                                defaultValue={null as any}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...workflowAutocompleteProps}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            if(typeof(item) === 'object'){
                                                return(
                                                    item.id + ' - ' +
                                                    workflowAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.id?.toString(),
                                                    )?.descProximoStatus ?? ""
                                                );
                                            }else{
                                                return(
                                                    item + ' - ' +
                                                    workflowAutocompleteProps?.options?.find(
                                                        (p) =>
                                                            p?.id?.toString() ===
                                                            item?.toString(),
                                                    )?.descProximoStatus ?? ""
                                                );
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            value === undefined ||
                                            option?.id?.toString() === value?.id?.toString()
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate("pages.pedidos.fields.idProximoStatus")}
                                                margin="normal"
                                                variant="outlined"
                                                error={!!(errors as any)?.idProximoStatus}
                                                helperText={
                                                    (errors as any)?.idProximoStatus?.message
                                                }
                                                style={{
                                                    width: "50%"
                                                }}             
                                                required
                                            />
                                        )}
                                        disabled={!canApprove}
                                    />
                                )}                                
                            />       
                            <TextField
                                {...register("descJustificativa", {
                                    // required: "This field is required",
                                })}
                                error={!!(errors as any)?.descJustificativa}
                                helperText={(errors as any)?.descJustificativa?.message}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                multiline
                                minRows={3}
                                maxRows={3}
                                inputProps={{maxLength:2048}}
                                label={translate("pages.pedidos.etapas.fields.descJustificativa")}
                                name="descJustificativa" 
                                disabled={!canApprove}                           
                            />                        
                        </Box>
                    </List>
                    <Modal
                        open={openHistoryModal}
                        onClose={handleClose__HistoryModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={styleHistoryModal}>
                            <IconButton sx={{position:"absolute",top:"0",right:"0"}} onClick={handleClose__HistoryModal}><CloseOutlined /></IconButton>                            
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {translate("pages.pedidos.etapas.titles.modal")}
                            </Typography>
                            <Stack>
                                <TableContainer component={Paper} sx={{padding:"16px",minWidth:"100%"}}>
                                    <Table>
                                        {/* <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {translate("pages.pedidos.etapas.titles.modal")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead> */}
                                        <TableBody sx={{overflowY:"scroll",display:"block",maxHeight:"300px"}}>
                                            {etapas?.map((row) => (
                                                <TableRow key={"etapa-"+row.id} sx={{tableLayout:"fixed",display:"table"}}>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" sx={{ color: "text.primary" }} fontWeight={700}>{row.nuInteracao}: {autorEtapa?.data?.find((usuario) => usuario.id === row.idUsuarioInclusao)?.nomeUsuario} - {new Date(row.dhInclusao).toLocaleDateString('pt-BR',{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</Typography>
                                                        <Typography variant="caption" sx={{ color: "text.primary" }}>{statusAntEtapa?.data.find((status) => status.id === row.idStatusAnterior)?.nomeStatus} {"=>"} {statusNovoEtapa?.data.find((status) => status.id === row.idStatusNovo)?.nomeStatus}</Typography>
                                                        <Typography variant="body2"  mt={1}>{row.descJustificativa}</Typography>                                                                                                            
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </Box>
                    </Modal>                    
                </AccordionDetails>
            </Accordion>

            <CreateItemPedidoDrawer {...createDrawerFormProps} />
            <EditItemPedidoDrawer {...editDrawerFormProps} />               
        </Edit>     
    );
};  